import { get as _get } from "lodash";

function getEnvSubdomain(hostname: string) {
    const regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
    const urlParts = regexParse.exec(hostname);
    return hostname.replace(urlParts ? urlParts[0] : "", '').slice(0, -1);
}
export default (): string => {
    const subDomain: string = getEnvSubdomain(_get(window, "location.hostname", ""));
    if(_get(window, "location.hostname", "") === "localhost") {
        return "dev";
    }
    if(subDomain.includes("dev-")) {
        return "dev";
    } else if(subDomain.includes("stage-")) {
        return "stage";
    } else {
        return subDomain;
    }
};
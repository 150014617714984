import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Apartment } from "../../../types/apartment";

export interface ApartmentState {
  apartments: Apartment[] | null;
  apartmentError: string | null;
  isApartmentFetching: boolean;
}

const initialState: ApartmentState = {
  apartments: [],
  apartmentError: null,
  isApartmentFetching: false,
};

const apartmentsSlice = createSlice({
  name: "apartmentState",
  initialState,
  reducers: {
    fetchingApartments(state) {
      state.isApartmentFetching = true;
    },
    setApartments(state, action: PayloadAction<Apartment[]>) {
      state.apartments = action.payload;
      state.apartmentError = null;
      state.isApartmentFetching = false;
    },
    setApartmentsFailed(state, action: PayloadAction<string>) {
      state.apartments = null;
      state.apartmentError = action.payload;
      state.isApartmentFetching = false;
    },
  },
});

export const {
  fetchingApartments,
  setApartments,
  setApartmentsFailed,
} = apartmentsSlice.actions;

export default apartmentsSlice.reducer;

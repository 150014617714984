export default {
  palette: {
    primary: {
      // light: "#ffecf0",
      main: "#ff5959",
      // dark: "#461919",
      // contrastText: "#fff",
    },
    // secondary: {
    //   // light: "#dff6f8",
    //   main: "#025a5c",
    //   // dark: "#002929",
    //   // contrastText: "#fff",
    // },
    text: {
      primary: "#000000",
      secondary: "#00000099",
    },
    action: {
      // hover: "#ffecf0"
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 992,
      lg: 1280,
      xl: 1920,
    },
  },
  // overrides: {
  //   MuiListItem: {
  //     root: {
  //       width: "auto",
  //       "&$selected": {
  //         marginLeft: 5,
  //         marginRight: 5,
  //         borderRadius: 4,
  //         backgroundColor: "#ffecf0",
  //       },
  //       "&&:hover": {
  //         marginLeft: 5,
  //         marginRight: 5,
  //         borderRadius: 4,
  //         backgroundColor: "#ffecf0",
  //       },
  //     },
  //   },
  // },
  logoUrl: "https://avy.se/propertyowner/mina/avy/logomain.png",
  loginLeftImageUrl: "https://avy.se/propertyowner/mina/avy/left.jpg",
  logoTopHeader: "https://avy.se/propertyowner/mina/avy/logowhite.png"
};

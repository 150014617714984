import { AppThunk } from "../../store/initStore";
import { UserType } from "../../types/authService";
import {
  fetchingProfileDetail,
  setProfileDetail,
  setProfileDetailFailed,
  setFeatureConfiguration,
  setFeatureConfigurationFailed,
  fetchUserDetails,
  setUserDetails,
  setUserDetailsFailed,
} from "../../store/slices/User/userSlice";
import { getProfileDetail, getFeatureConfig, getUserDetails } from "../../lib/User/userApi";

export const fetchProfileData = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchingProfileDetail());
    const profile = await getProfileDetail();
    dispatch(setProfileDetail(profile));
    return profile;
  } catch (err) {
    dispatch(setProfileDetailFailed(err.toString()));
  }
};

export const fetchFeatureConfigData = (): AppThunk => async dispatch => {
  try {
    const featureConfig = await getFeatureConfig();
    console.log("=====featureConfig====", featureConfig);
    const { flags, config } = featureConfig;
    const featureMappedObj: any = {};
    if (flags) {
      flags.map((flag: any) => { featureMappedObj[flag] = true; });
    }
    dispatch(setFeatureConfiguration({ config, flags: featureMappedObj }));
    return config;
  } catch (err) {
    dispatch(setFeatureConfigurationFailed(err.toString()));
  }
};

export const fetchUserData = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchUserDetails());
    const userDetails = await getUserDetails();
    dispatch(setUserDetails(userDetails));
    return userDetails;
  } catch (err) {
    dispatch(setUserDetailsFailed(err.toString()));
  }
};

import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./slices/User/userSlice";
import invoiceReducer from "./slices/Invoices/invoiceSlice";
import articleReducer from "./slices/Articles/articleSlice";
import apartmentReducer from "./slices/Apartment/apartmentSlice";
import errorMessageSlice from "./slices/Common/errorMessageSlice";

const rootReducer = combineReducers({
  user: userReducer,
  invoices: invoiceReducer,
  articles: articleReducer,
  apartment: apartmentReducer,
  error: errorMessageSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

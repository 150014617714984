import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ErrorPayload {
  message: string;
  flag: boolean;
}
export interface ArticleState {
  errorMessage: string;
  errorFlag: boolean;
}

const initialState: ArticleState = {
  errorMessage: "",
  errorFlag: false
};

const errorMessageSlice = createSlice({
  name: "errorState",
  initialState,
  reducers: {
    setErrorMessage(state, action: PayloadAction<ErrorPayload>) {
      state.errorMessage = action.payload.message;
      state.errorFlag = action.payload.flag;
    },
  },
});

export const {
  setErrorMessage
} = errorMessageSlice.actions;

export default errorMessageSlice.reducer;

import React, { Component, Suspense } from "react";
import { Route, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import { AVYSpinner } from "./components/global/Spinner";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
// import theme from "./theme";
import themeObject from "./theme/avyTheme";

import store from "./store/initStore";
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import "./App.scss";

import { setAccessToken } from "./store/slices/User/userSlice";
import { fetchUserData, fetchFeatureConfigData } from "./services/Profile/profile";
import configVar from "./config";

var mixpanel = require('mixpanel-browser');
mixpanel.init(configVar.REACT_APP_MIXPANEL_TOKEN);

let persistor = persistStore(store);

const Routing = React.lazy(() => import("./routing"));

const accessToken = localStorage.getItem("accessToken");

class App extends Component {
  state = {
    theme: createMuiTheme({
      palette: themeObject.palette,
      typography: themeObject.typography,
      // overrides: themeObject.overrides,
      breakpoints: themeObject.breakpoints,
    })
  };
  constructor(props: {}) {
    super(props);
    // not call this when perform logout because call data comes later and reset redux state
    if (accessToken && window.location.pathname !== "/logout") {
      store.dispatch(fetchUserData());
      this.fetchConfigDetails();
      // store.dispatch(setAccessToken(accessToken));
    }
  }

  fetchConfigDetails = async () => {
    const config:any = await store.dispatch(fetchFeatureConfigData());
    const {primaryColor, primary, secondary} = config;
    console.log("primaryColor", primaryColor);
    try {
      if(primaryColor) {
        // const primaryJSON = JSON.parse(primary);
        const primaryJSON = {
          main: primaryColor
        }
        themeObject.palette.primary = {...themeObject.palette.primary, ...primaryJSON};
      }
      // if(secondary) {
      //   const secondaryJSON = JSON.parse(secondary);
      //   themeObject.palette.primary = {...themeObject.palette.secondary, ...secondaryJSON};
      // }
      this.setState({theme: createMuiTheme({
        palette: themeObject.palette,
        typography: themeObject.typography,
        // overrides: themeObject.overrides,
        breakpoints: themeObject.breakpoints,
      })});
    } catch (error) {
      console.log("In ERROR in JSON parse", error);
    }
  }

  render() {
    const {theme} = this.state;
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense
            fallback={
              <div style={{ marginTop: "25%", marginLeft: "45%" }}>
                <AVYSpinner color="secondary" />
              </div>
            }
          >
            <BrowserRouter>
              {/* <Alert /> */}
              <ThemeProvider theme={responsiveFontSizes(theme)}>
                <Route path="/*" component={Routing} />
              </ThemeProvider>
            </BrowserRouter>
          </Suspense>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;

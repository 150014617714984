import getEnvSubdomain from "./helpers/getEnvSubDomain";
var config = {
    REACT_APP_API_URL: "https://avy.se/backend/api/v2/",
    REACT_APP_BANKID_RIDIRECT_URL: "http%3A%2F%2Fapp-test.avy.se%3Fautostarttoken%3D",
    REACT_APP_MIXPANEL_TOKEN: "823fcec963c5579610c5a7e543c07f90",
    REACT_APP_SERVER_URL: "https://api.avy.se/api/v1/"
};
if (getEnvSubdomain() === 'dev') {
  config = {
    REACT_APP_API_URL: "https://www.avydev.xyz:92/api/v2/",
    REACT_APP_BANKID_RIDIRECT_URL: "http%3A%2F%2Fapp-test.avy.se%3Fautostarttoken%3D",
    REACT_APP_MIXPANEL_TOKEN: "659160d2987c8bf1cb4f21b4e80739d4",
    REACT_APP_SERVER_URL: "https://dev-api.avy.se/api/v1/"
  }
}
if (getEnvSubdomain() === 'stage') {
  config = {
    REACT_APP_API_URL: "https://avy.se:92/api/v2/",
    REACT_APP_BANKID_RIDIRECT_URL: "http%3A%2F%2Fapp-test.avy.se%3Fautostarttoken%3D",
    REACT_APP_MIXPANEL_TOKEN: "659160d2987c8bf1cb4f21b4e80739d4",
    REACT_APP_SERVER_URL: "https://stage-api.avy.se/api/v1/"
  }
}
console.log("getEnvSubdomain()====", getEnvSubdomain());
export default config;

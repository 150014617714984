import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

interface SpinnerProps {
  className?: string;
  color?: "inherit" | "primary" | "secondary";
}

export const AVYSpinner = ({ className, color }: SpinnerProps) => {
  return <CircularProgress className={className} color={color} />;
};

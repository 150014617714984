// This File will be change when we will support multiple language
// https://react.i18next.com/latest/using-with-hooks#configure-i-18-next

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Translation from "./locales/sv/translation.json";
import moment from "moment";
import "moment/locale/sv";
moment.locale("sv");

// const data = await import("../locales/sv/translation.json");

const resources = {
  sv: {
    translation: Translation,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "sv",

    keySeparator: ".", // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

import { makeGetRequest, makePostRequest } from "../api/httpHelper";
import {RegistrationPayloadType, SendOtpPayloadType, VerifyOtpPayloadType} from "../../types/authService";
import config from "../../config";

// const basePath = process.env.REACT_APP_SERVER_URL;
const basePath = config.REACT_APP_SERVER_URL;


export async function getBankIdToken(): Promise<any> {
  const url = `auth/bankid/auth`;

  try {
    const response: any = await makePostRequest<any>(url, {}, "", basePath);
    const { auto_start_token = "", order_ref = "" } = response;
    console.log("getBankIdToken", auto_start_token, order_ref);
    return { autoStartToken: auto_start_token, orderRef: order_ref };
  } catch (err) {
    throw err;
  }
}

export async function getLoginToken(
  orderRef: string
): Promise<any> {
  const url = `auth/bankid/login`;

  try {
    const response: any = await makePostRequest<any>(url, { order_ref: orderRef }, "", basePath);
    console.log("Get Login Token", response);
    return response;
  } catch (err) {
    throw err;
  }
}

export async function collectBankIdStatus(
  orderRef: string
): Promise<any> {
  const url = `auth/bankid/collect`;

  try {
    const response: any = await makePostRequest<any>(url, { order_ref: orderRef }, "", basePath);
    console.log("collectBankIdStatus", response);
    return response;
  } catch (err) {
    throw err;
  }
}

export async function registerUser(
  reqPayload: RegistrationPayloadType
): Promise<any> {
  const url = `auth/activate`;
  try {
    const response: any = await makePostRequest<any>(url, reqPayload, "", basePath);
    // const response = await makePostRequest<any>(url, reqPayload);
    console.log("Regiser User", response);
    return response;
  } catch (err) {
    throw err;
  }
}

export async function smsAuthSendCode(
  reqPayload: SendOtpPayloadType
): Promise<any> {
  const url = `auth/sms/auth`;
  try {
    const response: any = await makePostRequest<any>(url, reqPayload, "", basePath);
    console.log("Send Code", response);
    return response;
  } catch (err) {
    throw err;
  }
}

export async function smsAuthVerifyCode(
  reqPayload: VerifyOtpPayloadType
): Promise<any> {
  const url = `auth/sms/login`;
  try {
    const response: any = await makePostRequest<any>(url, reqPayload, "", basePath);
    console.log("Send Code", response);
    return response;
  } catch (err) {
    throw err;
  }
}

export async function updateKycCitizenship(isDoubleCitizenship: boolean, countryList?: any[]): Promise<any> {
  const url = 'auth/update-citizenship';
  try {
    const response: any = await makePostRequest<any>(url, { double_citizenship: isDoubleCitizenship, countries: countryList }, "", basePath);
    // const response = await makePostRequest<any>(url, { isDoubleCitizenship, countries: countryList });
    console.log("updateKycCitizenship", response);
    return response;
  } catch (err) {
    throw err;
  }
}

/*TODO To Change to GO API*/
export async function getProfileDetail(): Promise<any> {
  const url = `/tenants/idTrackInfo`;

  try {
    const response = await makeGetRequest<any>(url);
    console.log("getProfileDetail", response);
    return response;
  } catch (err) {
    throw err;
  }
}

/*TODO To Change to GO API*/
export async function getTenantInfo(): Promise<any> {
  const url = `/tenants`;

  try {
    const response = await makeGetRequest<any>(url);
    console.log("getTenantInfo", response);
    return response;
  } catch (err) {
    throw err;
  }
}

/*TODO To Change to GO API*/
async function updatePersonal(url: string): Promise<any> {
  try {
    const response = await makePostRequest<any>(url, {});
    console.log("updatedEmailId", response);
    return response;
  } catch (err) {
    throw err;
  }
}

export async function updateEmailAddress(updatedEmailId: string): Promise<any> {
  const url = `/tenants/updateEmail?email=${updatedEmailId}`;
  const response = await updatePersonal(url);
  return response;
}

export async function updatePhoneNumber(phoneNumber: string): Promise<any> {
  const url = `/tenants/updatePhoneNumber?phoneNumber=${phoneNumber}`;
  const response = await updatePersonal(url);
  return response;
}

export async function getFeatureConfig(): Promise<any> {
  const url = `features`;
  try {
    const response = await makeGetRequest<any>(url, basePath);
    console.log("Get New feature config", response);
    return response;
  } catch (err) {
    throw err;
  }
}

export async function getUserDetails(): Promise<any> {
  const url = `auth/profile`;

  try {
    const response = await makeGetRequest<any>(url, basePath);
    console.log("get user details", response);
    return response;
  } catch (err) {
    throw err;
  }
}

export async function logoutUser(): Promise<any> {
  const url = `auth/logout`;

  try {
    const response: any = await makePostRequest<any>(url, {}, "", basePath);
    console.log("Logout user", response);
    return response;
  } catch (err) {
    throw err;
  }
}

export async function getPublicFeatureConfig(subDomain: string): Promise<any> {
  const url = `config?subdomain=${subDomain}`;
  try {
    const response = await makeGetRequest<any>(url, basePath);
    console.log("Get Public feature config", response);
    return response;
  } catch (err) {
    throw err;
  }
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ArticleState {
  error: string | null;
  contactusDetail: object | null;
  isFetching: boolean;
}

const initialState: ArticleState = {
  error: null,
  contactusDetail: null,
  isFetching: false,
};

const articleSlice = createSlice({
  name: "articleState",
  initialState,
  reducers: {
    fetchingContactUsDetail(state) {
      state.isFetching = true;
    },
    setContactUsDetail(state, action: PayloadAction<object>) {
      state.contactusDetail = action.payload;
      state.error = null;
      state.isFetching = false;
    },
    setContactUsFailed(state, action: PayloadAction<string>) {
      state.contactusDetail = null;
      state.error = action.payload;
      state.isFetching = false;
    },
  },
});

export const {
  fetchingContactUsDetail,
  setContactUsDetail,
  setContactUsFailed,
} = articleSlice.actions;

export default articleSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Invoice } from "../../../types/invoices";

export interface InvoiceState {
  invoices: Invoice[] | null;
  unPaidInvoices: Invoice[] | null;
  paidInvoices: Invoice[] | null;
  invoiceDetail: Invoice | null;
  error: string | null;
  isFetching: boolean;
}

const initialState: InvoiceState = {
  invoices: [],
  paidInvoices: [],
  unPaidInvoices: [],
  invoiceDetail: null,
  error: null,
  isFetching: false,
};

const invoicesSlice = createSlice({
  name: "invoiceState",
  initialState,
  reducers: {
    fetchingUnPaidInvoices(state) {
      state.isFetching = true;
    },
    setUnPaidInvoices(state, action: PayloadAction<Invoice[]>) {
      state.invoices = action.payload;
      state.unPaidInvoices = action.payload;
      state.error = null;
      state.isFetching = false;
    },
    setUnPaidInvoicesFailed(state, action: PayloadAction<string>) {
      state.invoices = null;
      state.unPaidInvoices = null;
      state.error = action.payload;
      state.isFetching = false;
    },
    fetchingPaidInvoices(state) {
      state.isFetching = true;
    },
    setPaidInvoices(state, action: PayloadAction<Invoice[]>) {
      state.paidInvoices = action.payload;
      state.error = null;
      state.isFetching = false;
    },
    setPaidInvoicesFailed(state, action: PayloadAction<string>) {
      state.paidInvoices = null;
      state.error = action.payload;
      state.isFetching = false;
    },
  },
});

export const {
  fetchingUnPaidInvoices,
  setUnPaidInvoices,
  setUnPaidInvoicesFailed,
  fetchingPaidInvoices,
  setPaidInvoices,
  setPaidInvoicesFailed
} = invoicesSlice.actions;

export default invoicesSlice.reducer;

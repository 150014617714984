import axios from "axios";
import store from "../../store/initStore";
import { setErrorMessage } from "../../store/slices/Common/errorMessageSlice";
import { get as _get } from "lodash";
import config from "../../config";
const client = axios.create({});
client.defaults.baseURL = config.REACT_APP_API_URL;
const acceptKey = "Accept";
const authKey = "Authorization";
client.defaults.headers.common[acceptKey] =
  "application/json, application/xml, text/plain, text/html ,*.*";
client.defaults.headers.post["Content-Type"] = "application/json";
function setAuthorisationHeader() {
  const accessToken =
    localStorage.getItem("accessToken") ||
    sessionStorage.getItem("accessToken");
  if (accessToken) {
    client.defaults.headers.common[authKey] = `Bearer ${accessToken}`;
  }
}
client.interceptors.response.use(response => {
  return response
}, err => {
  const request = err.config;
  console.log("Error in interceptor", err.response);

  if (err.response && err.response.status === 401) {
    console.log("Request failed 401!");
    store.dispatch(setErrorMessage({ message: _get(err, "response.data.Message", "You are not authorized to access the portal, for more information contact support team."), flag: true }));
    setTimeout(() => {
      store.dispatch(setErrorMessage({message: "", flag: false}));
      const smsLoginNumber = localStorage.getItem("smsPhoneNumber");
      const smsLoginPersonal = localStorage.getItem("smsPersonalNumber");
      localStorage.clear();
      if (smsLoginNumber != null) {
        localStorage.setItem("smsPhoneNumber", smsLoginNumber);
      }
      if (smsLoginPersonal != null) {
        localStorage.setItem("smsPersonalNumber", smsLoginPersonal);
      }
      window.location.href = "/login";
    }, 2000);
  }
  if (err.response && err.response.status === 500) {
    console.log("Request failed 500!");
    store.dispatch(setErrorMessage({ message: _get(err, "response.data.Message", "Something went wrong"), flag: true }));
    setTimeout(() => {
      store.dispatch(setErrorMessage({message: "", flag: false}));
    }, 2000)
  }
  return Promise.reject(err);
});

export async function makePostRequest<T>(
  url: string,
  payLoad?: any,
  type?: string,
  basePath?: string,
): Promise<Response> {
  setAuthorisationHeader();
  try {
    const response: any = await client({ url, data: JSON.stringify(payLoad), baseURL: basePath ? basePath : config.REACT_APP_API_URL, method: 'post'});
    // const response: any = await client.post(url, JSON.stringify(payLoad));
    if (response.status !== 200) {
      return { apiStatus: response.status, ...response };
    }
    if (type === "downloadFile") {
      const file = await response.blob();
      return { apiStatus: response.status, ...({ file } as any) };
    } else {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function makeGetRequest<T>(url: string, basePath?: string): Promise<Response> {
  setAuthorisationHeader();
  try {
    const response: any = await client({ url, baseURL: basePath ? basePath :  config.REACT_APP_API_URL, method: 'get'});
    // const response: any = await client.get(url);
    console.log("Response data", response);
    if (response.status !== 200) {
      return { apiStatus: response.status, ...response };
    }
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

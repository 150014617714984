import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserProfile, FeatureConfiguration, Flags } from "../../../types/profile";
import { UserType } from "../../../types/authService";

export interface AuthState {
  error: string | null;
  accessToken: string | null;
  user: object | null;
  profileDetail: UserProfile | null;
  isFetching: boolean;
  featureConfig: FeatureConfiguration;
  userDetails: UserType;
  isUserDetailFetching: boolean;
}

const initialState: AuthState = {
  error: null,
  accessToken: null,
  user: null,
  profileDetail: null,
  isFetching: false,
  featureConfig: {
    config: {},
    flags: null
  },
  userDetails: {},
  isUserDetailFetching: false,
};

const userSlice = createSlice({
  name: "userState",
  initialState,
  reducers: {
    setAccessToken(state, action: PayloadAction<string>) {
      localStorage.setItem("accessToken", action.payload);
      state.accessToken = action.payload;
      state.error = null;
    },
    onUserLogout(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.user = null;
      state.accessToken = null;
    },
    fetchingProfileDetail(state) {
      state.isFetching = true;
    },
    setProfileDetail(state, action: PayloadAction<UserProfile>) {
      state.profileDetail = action.payload;
      state.error = null;
      state.isFetching = false;
    },
    setProfileDetailFailed(state, action: PayloadAction<string>) {
      state.profileDetail = null;
      state.error = action.payload;
      state.isFetching = false;
    },
    setFeatureConfiguration(
      state,
      action: PayloadAction<FeatureConfiguration>
    ) {
      state.featureConfig = action.payload;
      state.error = null;
    },
    setFeatureConfigurationFailed(state, action: PayloadAction<string>) {
      state.featureConfig = {config: {}, flags: null};
      state.error = action.payload;
    },
    fetchUserDetails(state) {
      state.isUserDetailFetching = true;
    },
    setUserDetails(state, action: PayloadAction<UserType>) {
      state.userDetails = action.payload;
      state.error = null;
      state.isUserDetailFetching = false;
    },
    setUserDetailsFailed(state, action: PayloadAction<string>) {
      state.userDetails = {};
      state.error = action.payload;
      state.isUserDetailFetching = false;
    }
  },
});

export const {
  setAccessToken,
  onUserLogout,
  fetchingProfileDetail,
  setProfileDetail,
  setProfileDetailFailed,
  setFeatureConfiguration,
  setFeatureConfigurationFailed,
  fetchUserDetails,
  setUserDetails,
  setUserDetailsFailed
} = userSlice.actions;

export default userSlice.reducer;
